/// <reference path="app.ts" />
interface iForm {
  form: HTMLFormElement;
  url: string;
  dataArray: any;
  form_type: string;
  validation(): boolean;
  ajax(): void;
}

class Form implements iForm {
  form: HTMLFormElement;
  url:string;
  dataArray: any;
  form_type: string;
  constructor (form:HTMLFormElement, url:string, dataArray:any, form_type:string) {
    this.form = form;
    this.url = url;
    this.dataArray = dataArray;
    this.form_type = form_type;
  }

  validation() : boolean {
    let valid:boolean = true;

    $.each($(this.form).find('input.required'), function(i, val) {
      //alert($(this).attr('name') + " " +$(this).val())
      //let a = $(this).val();
      if($(this).val() == "") {
        valid = false; 
        $(this).closest('label').addClass('error');
      } else {
        $(this).closest('label').removeClass('error'); 
      }
     });

    return valid;
  }

  ajax(): void {

    let valid:boolean = this.validation();
    if(valid) {

      let goal:string = this.form_type;
      $.ajax({
        type: "POST",
        url: this.url,
        processData: false,
        contentType: false,
        data: this.dataArray
      }).done(function(res) {
        //console.log(res);
        //if(res) {

          gtag('event', goal, { 'event_category': 'Forms'});
          yaCounter48500639.reachGoal(goal);
          showSuccess();   
        //}          
      }).fail(function(res) {
        console.log(res);
       });
    }
  }
}


$(function() {
    $('form.standart').submit(function(e) {
        e.preventDefault(); 
      
        //let dataArray = $(this).serializeArray();
        let dataArray = new FormData(this);
        let form_type = $(this).attr('name');
        let url = $(this).attr('action');
        let form:Form = new Form(this, url, dataArray, form_type);
        let response = form.ajax();
    });
});      